import {useEffect, useState} from "react";
import Loader from "../../components/UI/Loader";
import {useNavigate, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {axiosInstance} from "../../services";

const Login2 = () => {

    const { corporateUrl } = useParams();
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    /** get variables **/
    useEffect(() => {
        setUser({"email" : query.get("email"), "password" : query.get("password")})
    }, []);
    /** end **/

    useEffect(() => {
        if(user.email !== '' && user.password !== ''){
            handleSubmit();
        }
    }, [user]);

    async function handleSubmit() {
        // setShowError(false)
        try {
            const response = await axiosInstance.post('login', { ...user, url: corporateUrl });

            const loggedUser = response?.data?.user;

            if (loggedUser) {
                navigate('/home');
            }
        } catch (error) {
            navigate('/login');
        }
    }

    return (
        loading ? <Loader />
            :
            <></>

    );
}
export default Login2;
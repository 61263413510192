import Footer from "./Footer";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../../services";
import UserContext from "../../context/UserContext";
import CategoriesContext from "../../context/CategoriesContext";
import { Navigate } from "react-router-dom";
import Loader from "../UI/Loader";
import ErrorBoundary from "../UI/ErrorBoundary";
import useAsyncError from "../../hooks/useAsyncError";
import OccasionsContext from "../../context/OccasionsContext";
import TagsContext from "../../context/TagsContext";

const Layout = () => {
  const { setUser } = useContext(UserContext);
  const { setCategories } = useContext(CategoriesContext);
  const { setOccasions } = useContext(OccasionsContext);
  const { setTags } = useContext(TagsContext);
  const [foundUser, setFoundUser] = useState(true)
  const [loading, setLoading] = useState(true)
  const throwError = useAsyncError();
  const location = useLocation();
  const [headerLogo, setHeaderLogo] = useState(null)

  // Uso switch porque requiere no admite parámetros dinámicos
  const applyTheme = color => {
    document.body.classList.add("corporate-" + color);

    switch (color) {

      case 'black':
        require("../../assets/colors/black-grey.css")
        break;

      case 'blue':
        require("../../assets/colors/blue-grey.css")
        break;

      case 'board':
        require("../../assets/colors/board-grey.css")
        break;

      case 'fuchsia':
        require("../../assets/colors/fuchsia-grey.css")
        break;

      case 'green':
        require("../../assets/colors/green-grey.css")
        break;

      case 'grey':
        require("../../assets/colors/grey-grey.css")
        break;

      case 'navyblue':
        require("../../assets/colors/navyblue-grey.css")
        break;

      case 'orange':
        require("../../assets/colors/orange-grey.css")
        break;

      case 'red':
        require("../../assets/colors/red-grey.css")
        break;

      case 'violet':
        require("../../assets/colors/violet-grey.css")
        break;

      case 'skyblue':
        require("../../assets/colors/skyblue-grey.css")
        break;

      default: // Color azul de Fanbag por defecto
        break;
    }
  }

  useEffect(() => {
    const controller = new AbortController();

    // Guardo la info de usuario en el context
    const getUserData = async () => {
      try {
        const response = await axiosInstance.get("/get_user_data", { signal: controller.signal });
        const { userData, categoriesData, occasionsData, pbTagsData } = response?.data

        if (userData) {
          setCategories(categoriesData)
          setOccasions(occasionsData)
          setUser(userData)
          setFoundUser(true)
          setLoading(false)
          setHeaderLogo(userData?.logo)
          applyTheme(userData?.style)

          //Etiquetas de experiencias y giftcards
          setTags(pbTagsData)
        }

      } catch (error) {
        throwError(new Error(error));
      }
    }

    getUserData()

    return () => controller.abort();
  }, [])

  // Si no se encontró el usuario devulevo 404
  if (!foundUser) return <Navigate to="/not-found" replace />;

  return (
    loading ? <Loader />
      :
      <div id="back-to-top" className="panel">
        <Header headerLogo={headerLogo} />
        <ErrorBoundary location={location}>
          <Outlet />
        </ErrorBoundary>
        <Footer />
      </div>
  );
}

export default Layout;

import { useState } from "react";
import CategoriesContext from "./CategoriesContext";
import TagsContext from "./TagsContext";

const TagsState = (props) => {
  const [tags, setTags] = useState({});

  return (
    <TagsContext.Provider value={{tags, setTags}}>
      {props.children}
    </TagsContext.Provider>
  );
};
export default TagsState;
